import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { ChevronLeftIcon } from '@heroicons/react/solid'
import { SmsSubscriptionStatus, UserProfile } from '../../../__generated__/api-types-and-hooks'
import { NotificationSettingsWrapper } from './style'
import InputField from 'components/Common/InputField'
import Button from 'components/Common/Button'
import { Controller, useForm } from 'react-hook-form'
import CheckBoxField from '../CheckBoxField'
import ErrorMessage from '../ErrorMessage'
import ValidationSchemaBuilder from 'utils/ValidationSchemaBuilder'
import { yupResolver } from '@hookform/resolvers/yup'
export interface INotificationSettingsProps {
  user: UserProfile
  updateUserProfileAction: (payload: Partial<UserProfile>) => {
    type: 'UPDATE_USER_PROFILE_STARTED'
    payload: Partial<UserProfile>
  }
}

const schemaBuilder = new ValidationSchemaBuilder()
const phoneNumberSchema = schemaBuilder.mobileContactNumber().getSchema()

const formatPhoneNumber = (phoneNumber: string) => {
  if (!phoneNumber) return ''
  const nonDigitRegex = /\D/g
  const digitsOnly = phoneNumber.replace(nonDigitRegex, '').substring(0, 10)
  const output = digitsOnly.replace(/(\d{3})(\d{3})(\d{5})/, '$1-$2-$3')
  return output
}

const NotificationSettings = ({ updateUserProfileAction, user }: INotificationSettingsProps) => {
  const methods = useForm({
    mode: 'all',
    resolver: yupResolver(phoneNumberSchema),
  })
  const {
    control,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = methods

  const [showEditPhoneNumber, setShowEditPhoneNumber] = useState(true)
  const watchPhoneNumber = watch('mobileContactNumber')
  const [isSmsEnabled, setIsSmsEnabled] = useState(user?.isSmsEnabled)
  const [subscribedWithoutOptIn, setSubscribedWithoutOptIn] = useState(false)

  const handleEditPhoneNumber = () => {
    const nonDigitRegex = /\D/g
    const mobileContactNumber = getValues('mobileContactNumber')
    const digitsOnly = mobileContactNumber.replace(nonDigitRegex, '').substring(0, 11)
    updateUserProfileAction({
      mobileContactNumber: digitsOnly,
    })

    setShowEditPhoneNumber(true)
  }

  const disablePhoneNumberInput = () => {
    if (errors.mobileContactNumber) return true
    const nonDigitRegex = /\D/g
    const digitsOnly = watchPhoneNumber?.replace(nonDigitRegex, '')
    if (digitsOnly?.length !== 10) return true
    return false
  }

  const handleChangedSmsSubscriptionStatus = () => {
    if (user?.smsSubscriptionStatus !== SmsSubscriptionStatus.Subscribed && isSmsEnabled) {
      updateUserProfileAction({
        smsSubscriptionStatus: SmsSubscriptionStatus.Subscribed,
        isSmsEnabled: true,
      })
      return
    }
    if (user?.smsSubscriptionStatus !== SmsSubscriptionStatus.Subscribed && !isSmsEnabled) {
      setSubscribedWithoutOptIn(true)
      return
    }
    updateUserProfileAction({
      smsSubscriptionStatus: SmsSubscriptionStatus.UnSubscribed,
    })
  }

  const history = useHistory()

  return (
    <div className="grid-flow-row auto-rows-max pt-6 w-full">
      <div className="xs:ml-0 md:ml-12 mb-12">
        <div className="flex items-center cursor-pointer" onClick={() => history.goBack()}>
          <div className="flex-initial">
            <ChevronLeftIcon className={`h-7 w-7 inline mt-1 mr-2 text-primary`} />
          </div>
          <div className="flex-initial pl-2">
            <p className="text-lg mt-1 text-primary font-primary">ACCOUNT SETTINGS</p>
          </div>
        </div>
      </div>

      <NotificationSettingsWrapper>
        <h1 className="text-2xl smd:text-3xl md:text-4xl font-light mb-6">Notification Settings</h1>
        <p className="font-semibold text-primary-text mb-6">
          Get priority notifications straight to your phone! Stay informed on meeting requests,
          chats, due dates, and GoTackle updates.
        </p>

        <Controller
          name="mobileContactNumber"
          control={control}
          defaultValue={
            user?.mobileContactNumber
              ? formatPhoneNumber(user?.mobileContactNumber)
              : 'XXX-XXX-XXXXX'
          }
          render={({ field }) => (
            <InputField
              mask="(999) 999-99999"
              errors={errors.mobileContactNumber}
              className={`!font-bold !text-base !font-primary text-black-light ${
                showEditPhoneNumber ? 'bg-white border-none !px-0' : ''
              }`}
              {...field}
              label={'Mobile Phone Number'}
              readonly={showEditPhoneNumber}
              labelFontSize="base"
              onChange={(event) => {
                field.onChange(event.target.value)
              }}
            />
          )}
        />
        {showEditPhoneNumber ? (
          <Button
            variant="text"
            label="Change Number"
            onClick={() => {
              if (!user?.mobileContactNumber) {
                setValue('mobileContactNumber', '')
              }
              setShowEditPhoneNumber(false)
            }}
          />
        ) : (
          <Button
            variant="text"
            label="Save"
            onClick={handleEditPhoneNumber}
            disabled={disablePhoneNumberInput()}
          />
        )}
        <div className="mt-6">
          {!user?.isSmsEnabled ? (
            <div>
              <div className="flex">
                <CheckBoxField
                  value={isSmsEnabled}
                  label={'I agree to receive SMS'}
                  id={'isSmsEnabled'}
                  className="!mr-0"
                  onChange={(e) => {
                    setIsSmsEnabled(e.target.checked)
                    setSubscribedWithoutOptIn(false)
                  }}
                />
              </div>
              <p className="mt-2 text-sm">
                Your number remains completely private. By subscribing, you agree to receive
                messages at the phone number provided. Reply STOP to cancel. Messaging rates may
                apply.
              </p>
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className="mt-4">
          <Button
            variant={
              user?.smsSubscriptionStatus === SmsSubscriptionStatus.Subscribed
                ? 'secondary'
                : 'primary'
            }
            label={
              user?.smsSubscriptionStatus === SmsSubscriptionStatus.Subscribed
                ? 'Unsubscribe'
                : 'Subscribe'
            }
            onClick={handleChangedSmsSubscriptionStatus}
          />
        </div>
        {!isSmsEnabled && subscribedWithoutOptIn ? (
          <ErrorMessage id={'isSmsOptedIn'}>{'Agreement is required to subscribe.'}</ErrorMessage>
        ) : (
          <></>
        )}
      </NotificationSettingsWrapper>
    </div>
  )
}

export default NotificationSettings
